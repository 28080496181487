import { JsonRpc, RpcError } from "eosjs";
import { AsturianCouncils } from "../asturianCouncils";
import { AppleVarieties } from "../appleVarieties";
import { ActorsTypes, EventsTypes } from "../enums";

export class EosDataConnector {
  constructor(apiNodeUrl) {
    this._apiNodeUrl = apiNodeUrl;
    this._rpc = new JsonRpc(apiNodeUrl, { fetch });
  }

  get apiNodeUrl() {
    return this._apiNodeUrl;
  }

  set apiNodeUrl(apiNodeUrl) {
    this._apiNodeUrl = apiNodeUrl;
    this._rpc = new JsonRpc(apiNodeUrl, { fetch });
  }

  getInfo = async () => {
    let result = null;
    try {
      result = await this._rpc.get_info();
      return result;
    } catch (e) {
      console.log("\nCaught exception: " + e);
      if (e instanceof RpcError) console.log(JSON.stringify(e.json, null, 2));
    }
  };
  getBatch = async itemId => {
    try {
      var response = await this._rpc.get_table_rows({
        json: true,
        code: "sidrasturias",
        scope: "sidrasturias",
        table: "batches",
        limit: 1,
        upper_bound: itemId,
        index_position: "secondary",
        key_type: "i64",
        reverse: true
      });
      if (itemId - response.rows[0].firstlabel >= response.rows[0].totallabels) {
        response = { rows: [] };
      }
    } catch {
      var response = { rows: [] };
    }
    var data = response.rows[0];
    return data;
  };

  getEspumosa = async batchId => {
    try {
      var response = await this._rpc.get_table_rows({
        json: true,
        code: "sidrasturias",
        scope: "sidrasturias",
        table: "espbatches",
        limit: 1,
        upper_bound: batchId,
        key_type: "i64",
        reverse: true
      });
    } catch {
      var response = { rows: [] };
    }
    var data = response.rows[0];
    return data;
  };
  getItemOwnerHistory = async itemId => {
    var data = await this.getBatch(itemId);
    var ownerHistory = [];
    ownerHistory = [
      {
        type: EventsTypes.NEWOWNER,
        account: data.owners[0],
        timestamp: data.received
      }
    ];
    for (var i = 1; i < data.owners.length; i++) {
      ownerHistory.push({
        type: EventsTypes.NEWOWNER,
        account: data.owners[i],
        timestamp: data.owners_date[i]
      });
    }
    return ownerHistory;
  };

  getItemEventHistory = async itemId => {
    const espumosaAccounts = ['emilmartinez','gaiteropomar','sidragaitero','elgaiterovbf','murosdenalon'];
    var eventHistory = [];
    
    var data = await this.getBatch(itemId);

    if (data.received !== 0) {
      eventHistory.push({
        type: EventsTypes.NEWOWNER,
        account: data.owners[0],
        timestamp: data.received
      });

    }

    if (espumosaAccounts.includes(data.owners[0])) {

      const data_espumosa = await this.getEspumosa(data.id);
    
      if (data_espumosa.collected !== 0) {
        eventHistory.push({
          type: EventsTypes.COLLECTED,
          timestamp: data_espumosa.collected
        });
      }
      if (data_espumosa.received !== 0) {
        eventHistory.push({
          type: EventsTypes.NEWOWNER,
          account: data.owners[0],
          timestamp: data_espumosa.received
        });
  
      }
      if (data_espumosa.pressed !== 0) {
        eventHistory.push({
          type: EventsTypes.PRESSING,
          timestamp: data_espumosa.pressed
        });
      }
      if (data_espumosa.certified !== 0) {
        eventHistory.push({
          type: EventsTypes.QUALIFIED,
          timestamp: data_espumosa.certified
        });
      }
      if (data_espumosa.distributed !== 0) {
        eventHistory.push({
          type: EventsTypes.DISTRIBUTION,
          timestamp: data_espumosa.distributed
        });
      }
   
      // Espumosa events
      if (data_espumosa.bottleFermented !== 0) {
        eventHistory.push({
          type: EventsTypes.BOTTLEFERMENTATION,
          timestamp: data_espumosa.bottleFermented
        });
      }
      if (data_espumosa.deguelle !== 0) {
        eventHistory.push({
          type: EventsTypes.DEGUELLE,
          timestamp: data_espumosa.deguelle
        });
      }
      if (data_espumosa.filtered !== 0) {
        eventHistory.push({
          type: EventsTypes.FILTERED,
          timestamp: data_espumosa.filtered
        });
      }
      if (data_espumosa.depositFermented !== 0) {
        eventHistory.push({
          type: EventsTypes.DEPOSITFERMENTATION,
          timestamp: data_espumosa.depositFermented
        });
      }
      if (data_espumosa.embotellao !== 0) {
        eventHistory.push({
          type: EventsTypes.BOTTLED,
          timestamp: data_espumosa.embotellado
        });
      }
    }
    else{
      if (data.collected !== 0) {
        eventHistory.push({
          type: EventsTypes.COLLECTED,
          timestamp: data.collected
        });
      }
      if (data.pressed !== 0) {
        eventHistory.push({
          type: EventsTypes.PRESSING,
          timestamp: data.pressed
        });
      }
      if (data.certified !== 0) {
        eventHistory.push({
          type: EventsTypes.QUALIFIED,
          timestamp: data.certified
        });
      }
      if (data.corked !== 0) {
        eventHistory.push({
          type: EventsTypes.CORKED,
          timestamp: data.corked
        });
      }
      if (data.distributed !== 0) {
        eventHistory.push({
          type: EventsTypes.DISTRIBUTION,
          timestamp: data.distributed
        });
      }
    }

    return eventHistory;
  };

  getItemCouncil = async itemId => {
    var data = await this.getBatch(itemId);
    var itemCouncil = data.counties;
    return itemCouncil;
  };

  getItemVarieties = async itemId => {
    var data = await this.getBatch(itemId);
    var rawVarieties = data.varieties;
    var varieties = [];
    for (var i = 0; i < rawVarieties.length; i++) {
      if (rawVarieties[i] != "") {
        console.log(rawVarieties[i].split(","));
        varieties.push({
          name: rawVarieties[i].split(",")[0],
          percent: rawVarieties[i].split(",")[1]
        });
      }
    }
    return varieties;
  };
  getItemLocations = async itemId => {
    var data = await this.getBatch(itemId);
    var rawLocations = data.locations;
    var locations = [];
    for (var i = 0; i < rawLocations.length; i++) {
      console.log(rawLocations[i].split(","));
      locations.push({
        name: rawLocations[i].split(",")[0],
        latitude: rawLocations[i].split(",")[1],
        longitude: rawLocations[i].split(",")[2]
      });
    }
    return locations;
  };
  getBatchName = async itemId => {
    var data = await this.getBatch(itemId);
    var batchName = "";
    if (data.batch_name !== "") {
      batchName = data.batch_name;
    }
    return batchName;
  }
  getTasting = async itemId => {
    var data = await this.getBatch(itemId);
    var tasting = "";
    if (data.tasting !== "") {
      tasting = data.tasting;
    }
    return tasting;
  }
  getActorInfo = async actorAccount => {
    var actor = {};
    var data = {};
    try {
      var response = await this._rpc.get_table_rows({
        json: true,
        code: "sidrasturias",
        scope: "sidrasturias",
        table: "actors",
        limit: 1,
        lower_bound: actorAccount
      });
      data = response.rows[0];
    } catch (error) { }
    if (actorAccount === data.ownerName) {
      actor = {
        account: data.ownerName,
        type: data.type,
        name: data.company,
        description: data.description,
        web: data.web,
        imageUrl: data.imageUrl,
        latitude: data.latitude,
        longitude: data.longitude,
        trademark: data.trademark,
        trademarkUrl: data.trademarkUrl,
        trademarkImageUrl: data.trademarkImageUrl
      };
    } else {
      actor = {
        account: actorAccount,
        type: "actors.typeEnum.winery",
        name: "",
        description: "",
        web: "",
        imageUrl: "",
        latitude: "",
        longitude: "",
        trademark: "",
        trademarkUrl: "",
        trademarkImageUrl: ""
      };
    }
    return actor;
  };
}
