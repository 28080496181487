import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LinkIcon from '@material-ui/icons/Link';
import Language from '@material-ui/icons/Language';
import StorageIcon from '@material-ui/icons/Storage';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import QRCode from 'qrcode.react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';

import { ActorsTypes, EventsTypes, ActorsTypesIcons, EventsTypesIcons, EventsTypesImages } from '../enums';
import { i18n } from '../i18n';
import moment from 'moment';
import { AsturianCouncilsMapIndex } from '../asturianCouncils';
import { AppleVarieties, AppleVarietiesImages } from '../appleVarieties';
import './TimelineCanvas.css';
import 'animate.css';

export default class TimelineCanvas extends Component {
  render() {
    const itemHistory = this.props.itemHistory;
    const lang = this.props.lang;
    const actors = this.props.actors;
    const blockExplorerUrl = 'https://eosauthority.com/account/sidrasturias?network=telos';
    const notFound = this.props.notFound;

    let batchAccount
    let espumosa = false // Variable to check if the item is espumosa

    // Check if the item is espumosa by reading its owner. If it is "espumosa", then it is espumosa (black)
    // if not, we show the green one
    const espumosaList = ['emilmartinez','gaiteropomar','sidragaitero','elgaiterovbf','murosdenalon']; // List of accounts that are espumosa
    // espumosaAccounts
    // loop itemHistory to check if the item is espumosa. Item is espumosa if its on the list of espumosa accounts (espumosaList)
    for (const element of itemHistory) {
      if (element.type === EventsTypes.NEWOWNER) {
         batchAccount = element.account
         break // Found account, stop loop
      }
    }
    if(espumosaList.includes(batchAccount)){ // If the account is in the list, then it is espumosa
      espumosa = true
    }

    //Transformation for the label IF searched through landing page
    var harvest = this.props.showLabel.slice(0, 2);
    harvest = harvest.toUpperCase();
    const number = this.props.showLabel.slice(2);
    //Transformation for the label IF accesed directly
    try {
      var code = 64 + parseInt(this.props.itemId.slice(0, 2));
      var harvestb = String.fromCharCode(code) + this.props.itemId.slice(2, 3);
      var numberb = this.props.itemId.slice(3);
    }
    catch {
      var harvestb = "";
      var numberb ="";
    }
    var year = "XXXX"
    //Search for year for the label
      try {
        itemHistory.map((item, index) => {
          if (item.type=="events.typeEnum.collected"){
            var date = new Date(item.timestamp*1000)
            year = date.getFullYear();
          }
        })
      }
      catch {
      }
    return (
      <div className="timeline-container">
        {notFound ? (
          <div></div>
        ) : this.props.batchName !== '' ? (
          <span className="item-batch">{i18n(lang, 'literals.batch') + ': ' + this.props.batchName}</span>
        ) : (
          <div></div>
        )}

        {espumosa ? (
          <a className="espumosa-item-id timelineHead animate__animated animate__fadeIn" href={blockExplorerUrl} target="_blank" rel="noopener noreferrer">
            {this.props.showLabel ?
              (<span className="num-label">{harvest + ' Nº ' + number}</span>) : (
                <span className="num-label">{harvestb + ' Nº ' + numberb}</span>
              )
            }
          </a>) : (
            <a className="item-id timelineHead animate__animated animate__fadeIn" href={blockExplorerUrl} target="_blank" rel="noopener noreferrer">
            {this.props.showLabel ?
              (<span className="num-label">{harvest + ' Nº ' + number}</span>) : (
                <span className="num-label">{harvestb + ' Nº ' + numberb}</span>
              )
            }
          </a>)
        }

        {notFound ? (
          <img
            className="not-found"
            src="/images/found.jpeg"
            alt="No encontramos la botella"
            style={{ paddingBottom: '20px' }}
          />
        ) : (
          <div>
            {this.props.tasting !== '' ? (
              <span className="item-tasting animate__animated animate__fadeIn">
                <span style={{ fontWeight: 'bold'}}> {espumosa ? "Sidra Natural espumosa": "Sidra Natural"}</span><br/><br/>
                <span style={{ fontWeight: 'bold', fontStyle: 'normal' }}>{i18n(lang, 'literals.tasting') + ': '}</span>
                <span>{this.props.tasting}</span>
              </span>
            ) : (
              <div></div>
            )}

            <ol className="timeline-main">
              {itemHistory &&
                itemHistory.map((item, index) => {
                  if (item.type === EventsTypes.COLLECTED) {
                    const date = moment.unix(item.timestamp).format('DD/MM/YY');
                    const councils = this.props.councils;
                    const varieties = this.props.varieties;
                    const locations = this.props.locations;
                    const title = councils.join('; ');
                    //const subtitle = i18n(lang, item.type);
                    const subtitle = i18n(lang, 'literals.originCouncil');
                    const titleIcon = 'fa-apple-alt';
                    return (
                      <TimelineItem
                        key={index}
                        position={'right'}
                        timepointText={date}
                        title={title}
                        subtitle={subtitle}
                        titleIcon={titleIcon}
                      >
                        <div style={{ position: 'relative' }}>
                          {councils &&
                            councils.map((council, index) => {
                              if (index === 0) {
                                const mapIndex = AsturianCouncilsMapIndex[council];
                                return (
                                  <img
                                    key={index}
                                    src={'/images/asturian-councils/' + mapIndex + '.png'}
                                    alt={council}
                                    style={{ width: '500px', maxWidth: '100%' }}
                                  />
                                );
                              } else {
                                const mapIndex = AsturianCouncilsMapIndex[council];
                                return (
                                  <img
                                    key={index}
                                    src={'/images/asturian-councils/' + mapIndex + '.png'}
                                    alt={council}
                                    style={{
                                      width: '500px',
                                      maxWidth: '100%',
                                      position: 'absolute',
                                      top: '0',
                                      left: '0',
                                    }}
                                  />
                                );
                              }
                            })}
                        </div>
                        <div style={{ clear: 'both' }} />
                        <div style={{ clear: 'both' }} />
                        <div>
                          <div>
                            <strong>{i18n(lang, 'literals.locations')}</strong>
                          </div>
                          <table className="locations">
                            <tbody>
                              {locations &&
                                locations.map((location, index) => {
                                  const lat = parseFloat(location.latitude);
                                  const lon = parseFloat(location.longitude);
                                  // https://developers.google.com/maps/documentation/urls/guide
                                  // https://stackoverflow.com/questions/47038116/google-maps-url-with-pushpin-and-satellite-basemap
                                  // const gmapsUrl = "https://www.google.com/maps/search/?api=1&query=" + lat + "," + lon;
                                  const gmapsUrl =
                                    'https://www.google.com/maps/@?api=1&map_action=map&center=' +
                                    lat +
                                    ',' +
                                    lon +
                                    '&zoom=17&basemap=satellite';
                                  return (
                                    <tr key={index}>
                                      {isNaN(lat) ? (
                                        <td></td>
                                      ) : (
                                        <td>
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            href={gmapsUrl}
                                            target="_blank"
                                            size="small"
                                          >
                                            <LocationOnIcon />
                                          </Button>
                                        </td>
                                      )}
                                      <td>{location.name}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <div>
                            <strong>{i18n(lang, 'literals.varieties')}</strong>
                          </div>
                          <table className="varieties">
                            <tbody>
                              {varieties &&
                                varieties.map((variety, index) => {
                                  const imageName = AppleVarietiesImages[variety.name];
                                  return (
                                    <AppleVariety
                                      key={index}
                                      imageName={imageName}
                                      variety={i18n(lang, variety.name)}
                                      percent={variety.percent}
                                    />
                                  );
                                })}
                            </tbody>
                          </table>
                          {varieties.length ? (
                            <div></div>
                          ) : (
                            <table className="varieties">
                              <tbody>
                                <AppleVariety
                                  key={0}
                                  imageName={'mezcla.jpg'}
                                  variety={i18n(lang, 'apples.varieties.mezcla')}
                                  percent={100}
                                />
                              </tbody>
                            </table>
                          )}
                          <br />
                          <br />
                        </div>
                      </TimelineItem>
                    );
                  } else if (item.type === EventsTypes.NEWOWNER) {
                    const date = moment.unix(item.timestamp).format('DD/MM/YY');
                    const actor = actors.find((a) => a.account === item.account);
                    const title = actor ? actor.name : '';
                    const subtitle = actor ? i18n(lang, actor.type) : '';
                    const titleIcon = actor ? ActorsTypesIcons[actor.type] : '';
                    const imageUrl = actor ? actor.imageUrl : '';
                    const description = actor ? actor.description : '';
                    const web = actor ? actor.web : '';
                    const latitude = actor ? actor.latitude : '';
                    const longitude = actor ? actor.longitude : '';
                    const mapLink = 'https://www.google.com/maps/search/?api=1&query=' + latitude + ',' + longitude;
                    const chainLink = 'https://eosauthority.com/account/' + actor.account + '?network=telos';
                    let trademark,
                      trademarkUrl,
                      trademarkImageUrl = null;
                    if (actor.type == ActorsTypes.WINERY) {
                      trademark = actor ? actor.trademark : '';
                      trademarkUrl = actor ? actor.trademarkUrl : '';
                      trademarkImageUrl = actor ? actor.trademarkImageUrl : '';
                    }
                    return (
                      <TimelineItem
                        key={index}
                        position={'right'}
                        timepointText={date}
                        title={title}
                        subtitle={subtitle}
                        titleIcon={titleIcon}
                      >
                        <br />
                        <div
                          style={{
                            border: '1px solid #999',
                            padding: '5px 5px 3px 5px ',
                            display: 'inline-block',
                          }}
                        >
                          <img src={imageUrl} alt="" style={{ width: '150px' }} />
                        </div>
                        <p>{description}</p>
                        <p>
                          <Button variant="outlined" color="primary" href={chainLink} target="_blank" size="small">
                            <LinkIcon />
                          </Button>
                          &nbsp;&nbsp;
                          <Button variant="outlined" color="primary" href={web} target="_blank" size="small">
                            <Language />
                          </Button>
                          &nbsp;&nbsp;
                          <Button variant="outlined" color="primary" href={mapLink} target="_blank" size="small">
                            <LocationOnIcon />
                          </Button>
                        </p>
                        <br />
                        {actor.type == ActorsTypes.WINERY ? (
                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left' }}>
                                    <img
                                      src={trademarkImageUrl}
                                      style={{
                                        maxHeight: '250px',
                                        maxWidth: '100%',
                                      }}
                                    />
                                  </td>
                                  <td valign="top" style={{ textAlign: 'left' }}>
                                    <strong style={{ fontSize: '1.1rem' }}>{trademark}</strong>
                                    <br />
                                    <br />
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      href={trademarkUrl}
                                      target="_blank"
                                      size="small"
                                    >
                                      <Language />
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : null}
                      </TimelineItem>
                    );
                  } else {
                    const date = moment.unix(item.timestamp).format('DD/MM/YY');
                    const title = i18n(lang, item.type);
                    const timepointIcon = EventsTypesIcons[item.type];
                    const image = EventsTypesImages[item.type];
                    let fermentation2 = item.type === EventsTypes.BOTTLEFERMENTATION || item.type === EventsTypes.DEPOSITFERMENTATION // if its a fermentation event, true or false

                    return (
                      <TimelineItem
                        key={index}
                        position={'left'}
                        timepointIcon={timepointIcon}
                        title={title}
                        subtitle={date}
                        fermentation2={fermentation2}
                        fermentation2type={item.type}
                      >
                        <br />
                        <img src={image} alt="" style={{ height: '75px' }} />
                        <br />
                        <br />
                        <br />
                      </TimelineItem>
                    );
                  }
                })}
              <TimelineItem
                position={'right'}
                timepointText={moment().format('DD/MM/YY')}
                title={i18n(lang, 'literals.you')}
                titleIcon={'fa-user-check'}
              >
                <br />
              </TimelineItem>
            </ol>
            <div
              style={{
                display: 'block',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <a href={blockExplorerUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#666' }}>
                {i18n(lang, 'literals.blockchainInfo')}
              </a>
            </div>
            <div className="check-share">
              <h3>{i18n(lang, 'literals.yourQuery')}</h3>

              <p>
                <QRCode value={window.location.href} bgColor={'#f0f0f0'} />
              </p>
              <p style={{ color: '#666' }}>{i18n(lang, 'content.show')}</p>
              <p style={{ display: 'inline' }}>
                <WhatsappShareButton
                  url={window.location.href}
                  title={i18n(lang, 'content.share')}
                  style={{ display: 'inline' }}
                >
                  <Button>
                    <WhatsappIcon></WhatsappIcon>
                  </Button>
                </WhatsappShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={i18n(lang, 'content.share')}
                  hashtags={['SidraDOP', 'CTIC', 'Telos']}
                  style={{ display: 'inline' }}
                >
                  <Button>
                    <TwitterIcon></TwitterIcon>
                  </Button>
                </TwitterShareButton>
                <FacebookShareButton
                  url={window.location.href}
                  quote={i18n(lang, 'content.share')}
                  style={{ display: 'inline' }}
                >
                  <Button>
                    <FacebookIcon></FacebookIcon>
                  </Button>
                </FacebookShareButton>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class TimelineItem extends React.PureComponent {
  render() {
    let secondFerDescription = "";
    if(this.props.fermentation2) {
      if (this.props.fermentation2type === EventsTypes.BOTTLEFERMENTATION) {
        secondFerDescription = "Proceso por el cual la levadura convierte el azúcar en alcohol y carbónico, este proceso se realiza en la botella y posteriormente tendrá una crianza mínima de 7 meses antes de realizar el " +
        "removido que consiste en llevar las lías (levadura) al cuello de la botella para realizar el degüelle y que la sidra quede sin turbios."
      } else if (this.props.fermentation2type === EventsTypes.DEPOSITFERMENTATION) {
        secondFerDescription = "Proceso por el cual la levadura convierte el azúcar en alcohol y carbónico, este proceso se realiza en grandes depósitos isobáricos (aguantan la presión de carbónico) "
        + "y posteriormente tendrá una crianza mínima de 4 meses antes de filtrarlo y embotellarlo sin perder el gas carbónico que se obtuvo durante la segunda fermentación (filtración isobárica)."
      }
    }
    const positionTimeline = this.props.fermentation2 ? "right" : this.props.position;
    return (
      <li className={'timeline-item ' + positionTimeline}>
        <div className="timeline-content">
          <div className="timepoint">
            {this.props.timepointIcon && <i className={'fa ' + this.props.timepointIcon} />}
            <span>{this.props.timepointText}</span>
          </div>
          <div className="inner-content">
            <h3 className="title">
              {this.props.title}
              {this.props.titleIcon && <i className={'fa ' + this.props.titleIcon} />}
            </h3>
            <div className="subtitle">{this.props.subtitle}</div>
            <div className="description">{this.props.children}</div>
            {this.props.fermentation2 ? secondFerDescription : ""}
          </div>
        </div>
      </li>
    );
  }
}

class AppleVariety extends React.PureComponent {
  render() {
    return (
      <tr>
        <td style={{ minWidth: '50px' }}>
          <a
            className="appleLink"
            href="https://sidradeasturias.es/variedades/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={'/images/apple-varieties/' + this.props.imageName}
              alt={this.props.variety}
              style={{ width: '60px', maxWidth: '100%' }}
            />
          </a>
        </td>
        <td>{this.props.variety}</td>
        <td>&nbsp;&nbsp;{parseInt(this.props.percent)}%</td>
      </tr>
    );
  }
}
