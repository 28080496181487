
import { JsonRpc, RpcError } from 'eosjs';
import { AsturianCouncils } from '../asturianCouncils';
import { AppleVarieties } from '../appleVarieties';
import { ActorsTypes, EventsTypes } from '../enums';
// let _ = require("underscore");

const actors = [
  {
    account: "crdopsatraza",
    type: ActorsTypes.ISSUER,
    name: "Consejo Regulador de la Denominación de Origen Protegida Sidra de Asturias",
    description: "El Consejo Regulador de la DENOMINACIÓN DE ORIGEN  es el Organismo Oficial acreditado opara certificar que una sidra se califique como Denominación de Origen \"Sidra de Asturias\"",
    web: "http://www.sidradeasturias.es/",
    imageUrl: "http://www.sidradeasturias.es/upload/consejoedificio.jpg",
    latitude: "43.4784558",
    longitude: "-5.4333103",
  },
  {
    account: "trabancotraz",
    type: ActorsTypes.WINERY,
    name: "Trabanco",
    description: "En Lavandera, en plena naturaleza y a pocos kilómetros de Gijón, se sitúa nuestro primer llagar, en donde en 1925 empezó todo. Hoy en día, sigue siendo nuestro centro de trabajo, la sede de la sidrería Casa Trabanco y un punto de encuentro para los muchos amigos del mundo de la sidra que nos visitan cada año para conocer nuestras instalaciones, dentro de nuestro plan de sidroturismo.",
    web: "http://www.sidratrabanco.com",
    imageUrl: "http://www.sidratrabanco.com/data/fotos/llagares/xx_llagar_trabanco.jpg.pagespeed.ic.AbPPTIgjzy.jpg",
    latitude: "43.470483",
    longitude: "-5.644929",
    trademark: "Sidra Trabanco DOP Seleccionada",
    trademarkUrl: "http://www.sidratrabanco.com/producto-sidra-natural-sidra-trabanco-dop-seleccionada-es.html",
    trademarkImageUrl: "http://www.sidratrabanco.com/data/fotos/productos/g_sidra_trabanco_cosecha_propia_1211.png"
  },
  {
    account: "sidracortina",
    type: ActorsTypes.WINERY,
    name: "Sidra Cortina",
    description: "En Sidra Cortina se fusionan tecnología y tradición, nuestras manzanas mayadas año tras año siguen su curso habitual y se convierten en nuestra afamada sidra. En 2003 llega el momento de la Sidra de Asturias. Nace la denominación de origen protegida \"Sidra de Asturias\" y con ella nuestra sidra más preciada, VILLACUBERA.",
    web: "http://www.sidracortina.com/",
    imageUrl: "http://www.sidracortina.com/wp-content/uploads/bodega-1.jpg",
    latitude: "43.4657539",
    longitude: "-5.4457339",
    trademark: "Villacubera",
    trademarkUrl: "http://www.sidracortina.com/villacubera-sidra-de-asturias/",
    trademarkImageUrl: "http://www.sidracortina.com/wp-content/uploads/producto-4-pro-trans.png"
  },
  {
    account: "sidramenende",
    type: ActorsTypes.WINERY,
    name: "Llagar Sidra Menendez",
    description: "Hoy, la tercera generación de la familia Menéndez, fiel a la tradición y el buen hacer de sus antepasados, elabora sidra natural bajo unos patrones de calidad que satisfacen al consumidor más exigente. Val d'Ornon es una sidra fresca y elegante que denota el potencial de nuestras variedades autóctonas amparadas bajo el sello de calidad de la DOP Sidra de Asturias.",
    web: "https://www.sidramenendez.com/",
    imageUrl: "https://www.sidramenendez.com/system/cms_multimedia/cms_medias/files/000/000/061/original/toneles-sidra-menendez.jpg?1523282770",
    latitude: "43.461919",
    longitude: "-5.62107",
    trademark: "Sidra Natural Val d'Ornon DOP",
    trademarkUrl: "https://www.sidramenendez.com/sidra-natural-val-d-ornon",
    trademarkImageUrl: "https://static2.elcomercio.es/www/pre2017/multimedia/noticias/201501/21/media/cortadas/val-ornon--490x578.jpg"
  },
  {
    account: "sidracastano",
    type: ActorsTypes.WINERY,
    name: "Llagar Sidra Castañón",
    description: "Tradición familiar. La experiencia de tres generaciones y el mismo espíritu de superación para ofrecer la mejor sidra natural. Nuestra Val de Boides galardonada como la Mejor Sidra de Asturias 2015, sidra con Denominación de Origen Protegido. (D.O.P.).",
    web: "http://www.sidracastanon.com/",
    imageUrl: "http://www.sidracastanon.com/llagar_castanon/sidra_castanon_llagar_27.jpg",
    latitude: "43.5143889",
    longitude: "-5.5718267",
    trademark: "Sidra Val de Boides DOP",
    trademarkUrl: "http://www.sidracastanon.com/sidra.php",
    trademarkImageUrl: "http://www.sidracastanon.com/images/services/sidra22.png"
  },
  {
    account: "herminiotraz",
    type: ActorsTypes.WINERY,
    name: "Llagar Herminio",
    description: "Llagar Herminio inicio su andadura cuando D. Herminio Alonso Buznego fundó en 1943 nuestra marca, en Colloto, tradicional población sidrera del Principado de Asturias. En la actualidad Lagar Herminio S.L. sigue creciendo e intentando mantener los ideales con los que se inició allá en 1943.",
    web: "https://www.llagarherminio.com/",
    imageUrl: "https://www.llagarherminio.com/Imagenes/Sidreria5Med.jpg",
    latitude: "43.3768966",
    longitude: "-5.8066769",
    trademark: "Zythos",
    trademarkUrl: "https://www.llagarherminio.com/SidraZythosTradicional.html",
    trademarkImageUrl: "http://www.sidradeasturias.es/upload/240614164018nazithos.jpg"
  },
  {
    account: "disceastraza",
    type: ActorsTypes.DISTRIBUTOR,
    name: "Disceas",
    description: "Distribuidor de bebidad y alimentación.",
    web: "https://disceas.es/",
    imageUrl: "https://www.proveedores.com/site/company/3b/2693/images/5017/proveedores-almacenes_crs2.jpg",
    latitude: "43.3905157",
    longitude: "-5.7901396"
  },
  {
    account: "elmallutraza",
    type: ActorsTypes.VENDOR,
    name: "El Mallu del Infanzón",
    description: "El Mallu del Infanzón, nuestro merendero, es el lugar ideal para reunirte con familia y con amigos y pasar un día estupendo al aire libre. Disponemos de zona infantil con hinchables y columpios para los más pequeños. Para los mayores la diversión también está asegurada mientras disfrutas de manjares de la gastronomía asturiana como el cordero a al estaca, arroz con marisco o tus tapas favoritas.",
    web: "https://sidreriaselmallu.es/",
    imageUrl: "https://sidreriaselmallu.es/wp-content/uploads/2017/02/mg_0480.jpg",
    latitude: "43.5182208",
    longitude: "-5.5885498"
  },
  {
    account: "casachuchutr",
    type: ActorsTypes.VENDOR,
    name: "Casa Chuchu",
    description: "Bodegón fundado por Sabino Jesús y su esposa Iluminada en 1930 en Vistalegre (actualmente C/ La Salle). En 1939, despues de la contienda, se trasladan a San Andrés junto a sus 10 hijos. En 1965 uno de ellos (Rafael “Pichi”) sigue la trayectoria hostelera en la Veguina con su mujer Guillermina, en el antiguo almacén de ultramarinos de Celesto \"El Llarguín\".",
    web: "http://casachuchu.com/",
    imageUrl: "http://casachuchu.com/wp-content/uploads/2017/07/foto_01.jpg",
    latitude: "43.2096219",
    longitude: "-5.7442484"
  },
  {
    account: "llagarbegona",
    type: ActorsTypes.VENDOR,
    name: "El Llagar de Begoña",
    description: "En la Sidrera Llagar de Begoña podrá disfrutar de una comida o cena en un ambiente agradable. Visite nuestras instalaciones",
    web: "https://www.llagardebegona.com",
    imageUrl: "https://estaticos.qdq.com/swdata/home_photos/689/689823084/620547ff802c4b9e996a75ce15ce4aa0.jpg",
    latitude: "43.5423942",
    longitude: "-5.6651714"
  }
]

const itemOwnerHistories = [
  [
    {
      type: EventsTypes.NEWOWNER,
      account: "trabancotraz",
      timestamp: 1539599443
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "disceastraza",
      timestamp: 1549794643
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "elmallutraza",
      timestamp: 1550140243
    }
  ],
  [
    {
      type: EventsTypes.NEWOWNER,
      account: "sidracortina",
      timestamp: 1539599443
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "disceastraza",
      timestamp: 1549794643
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "casachuchutr",
      timestamp: 1550140243
    }
  ],
  [
    {
      type: EventsTypes.NEWOWNER,
      account: "herminiotraz",
      timestamp: 1539599443
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "disceastraza",
      timestamp: 1549794643
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "llagarbegona",
      timestamp: 1550140243
    }
  ],
  [
    {
      type: EventsTypes.NEWOWNER,
      account: "sidramenende",
      timestamp: 1539599443
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "disceastraza",
      timestamp: 1549794643
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "llagarbegona",
      timestamp: 1550140243
    }
  ],
  [
    {
      type: EventsTypes.NEWOWNER,
      account: "sidracastano",
      timestamp: 1539599443
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "disceastraza",
      timestamp: 1549794643
    },
    {
      type: EventsTypes.NEWOWNER,
      account: "elmallutraza",
      timestamp: 1550140243
    }
  ]
]

const itemAppleVarieties = [
  [
    {
      name: AppleVarieties.BLANQUINA,
      percent: 20
    },
    {
      name: AppleVarieties.COLORADONA,
      percent: 40
    },{
      name: AppleVarieties.COLORAAMARGA,
      percent: 40
    }
  ],
  [
    {
      name: AppleVarieties.CLARA,
      percent: 30
    },
    {
      name: AppleVarieties.CRISTALINA,
      percent: 70
    }
  ],
  [
    {
      name: AppleVarieties.DURA,
      percent: 30
    },
    {
      name: AppleVarieties.PARAGUAS,
      percent: 30
    },
    {
      name: AppleVarieties.PEREZOSA,
      percent: 40
    }
  ],
  [
    {
      name: AppleVarieties.COLLAINA,
      percent: 70
    },
    {
      name: AppleVarieties.PERICO,
      percent: 20
    },
    {
      name: AppleVarieties.PRIETA,
      percent: 10
    }
  ],
  [
    {
      name: AppleVarieties.REINETACARAVIA,
      percent: 60
    },
    {
      name: AppleVarieties.SOLARINA,
      percent: 40
    }
  ]
]


const itemLocations = [
  [
    {
      name: "La gatera",
      latitude: 20,
      longitude: 10
    },
    {
      name: "Calorra",
      latitude: 20,
      longitude: 10
    },{
      name: "Champanera",
      latitude: 20,
      longitude: 10
    }
  ],
  [
    {
      name: "La gatera",
      latitude: 20,
      longitude: 10
    },
    {
      name: "Calorra",
      latitude: 20,
      longitude: 10
    },{
      name: "Champanera",
      latitude: 20,
      longitude: 10
    }
  ],
  [
    {
      name: "La gatera",
      latitude: 20,
      longitude: 10
    },
    {
      name: "Calorra",
      latitude: 20,
      longitude: 10
    },{
      name: "Champanera",
      latitude: 20,
      longitude: 10
    }
  ],
  [
    {
      name: "Noval",
      latitude: 43.565513,
      longitude: -5.815719
    },
    {
      name: "Rebollada",
      latitude: 43.555050,
      longitude: -5.778378
    },{
      name: "Ciares",
      latitude: 43.516492,
      longitude: -5.646037
    },{
      name: "Fano",
      latitude: 43.467628,
      longitude: -5.625512
    }
  ],
  [
    {
      name: "La Pindial",
      latitude: 43.5209609,
      longitude: -5.454301
    },
    {
      name: "Larroyu",
      latitude: 43.494758,
      longitude: -5.451564
    },{
      name: "Caleyín",
      latitude: 43.491972,
      longitude: -5.259661
    },{
      name: "Llanona",
      latitude: 43.485106,
      longitude: -5.235925
    }
  ]
]

const itemEventHistories = [
  [
    {
      type: EventsTypes.COLLECTED,
      info: [AsturianCouncils.VILLAVICIOSA, AsturianCouncils.AVILES],
      timestamp: 1539340243
    },
    {
      type: EventsTypes.PRESSING,
      info: "",
      timestamp: 1539945043
    },
    {
      type: EventsTypes.QUALIFIED,
      info: "",
      timestamp: 1542277843
    },
    {
      type: EventsTypes.CORKED,
      info: "",
      timestamp: 1548671443
    },
    {
      type: EventsTypes.DISTRIBUTION,
      info: "",
      timestamp: 1549967443
    }
  ],
  [
    {
      type: EventsTypes.COLLECTED,
      info: [AsturianCouncils.ALLANDE],
      timestamp: 1539340243
    },
    {
      type: EventsTypes.PRESSING,
      info: "",
      timestamp: 1539945043
    },
    {
      type: EventsTypes.QUALIFIED,
      info: "",
      timestamp: 1542277843
    },
    {
      type: EventsTypes.CORKED,
      info: "",
      timestamp: 1548671443
    },
    {
      type: EventsTypes.DISTRIBUTION,
      info: "",
      timestamp: 1549967443
    }
  ],
  [
    {
      type: EventsTypes.COLLECTED,
      info: [AsturianCouncils.PENAMELLERAALTA],
      timestamp: 1539340243
    },
    {
      type: EventsTypes.PRESSING,
      info: "",
      timestamp: 1539945043
    },
    {
      type: EventsTypes.QUALIFIED,
      info: "",
      timestamp: 1542277843
    },
    {
      type: EventsTypes.CORKED,
      info: "",
      timestamp: 1548671443
    },
    {
      type: EventsTypes.DISTRIBUTION,
      info: "",
      timestamp: 1549967443
    }
  ],
  [
    {
      type: EventsTypes.COLLECTED,
      info: [AsturianCouncils.CARRENO, AsturianCouncils.GIJON],
      timestamp: 1539340243
    },
    {
      type: EventsTypes.PRESSING,
      info: "",
      timestamp: 1539945043
    },
    {
      type: EventsTypes.QUALIFIED,
      info: "",
      timestamp: 1542277843
    },
    {
      type: EventsTypes.CORKED,
      info: "",
      timestamp: 1548671443
    },
    {
      type: EventsTypes.DISTRIBUTION,
      info: "",
      timestamp: 1549967443
    }
  ],
  [
    {
      type: EventsTypes.COLLECTED,
      info: [AsturianCouncils.VILLAVICIOSA, AsturianCouncils.COLUNGA],
      timestamp: 1539340243
    },
    {
      type: EventsTypes.PRESSING,
      info: "",
      timestamp: 1539945043
    },
    {
      type: EventsTypes.QUALIFIED,
      info: "",
      timestamp: 1542277843
    },
    {
      type: EventsTypes.CORKED,
      info: "",
      timestamp: 1548671443
    },
    {
      type: EventsTypes.DISTRIBUTION,
      info: "",
      timestamp: 1549967443
    }
  ]
]


export class MockupDataConnector {
  constructor(apiNodeUrl) {
    this._apiNodeUrl = apiNodeUrl;
    this._rpc = new JsonRpc(apiNodeUrl, { fetch });
  }

  get apiNodeUrl() {
    return this._apiNodeUrl;
  }

  set apiNodeUrl(apiNodeUrl) {
    this._apiNodeUrl = apiNodeUrl;
    this._rpc = new JsonRpc(apiNodeUrl, { fetch });
  }

  getInfo = async () => {
    let result = null;
    try {
      result = await this._rpc.get_info();
      return result;
    } catch (e) {
      console.log('\nCaught exception: ' + e);
      if (e instanceof RpcError)
        console.log(JSON.stringify(e.json, null, 2));
    }
  }

  getItemOwnerHistory = async (itemId) => {
    return itemOwnerHistories[itemId - 1];
  }

  getItemEventHistory = async (itemId) => {
    return itemEventHistories[itemId - 1];
  }

  getItemVarieties = async (itemId) => {
    return itemAppleVarieties[itemId - 1];
  }

  getItemLocations = async (itemId) => {
    return itemLocations[itemId - 1];
  }

  getItemCouncil = async (itemId) => {
    return itemEventHistories[itemId - 1].find(item => item.type === EventsTypes.COLLECTED).info;
  }

  getActorInfo = async (actorAccount) => {
    return actors.find(actor => actor.account === actorAccount);
  }
  getBatchName =async (itemId) => {
    return "";
  }
};
