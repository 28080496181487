import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import TimelineCanvas from './TimelineCanvas';
import { Link } from 'react-router-dom';
import './Timeline.css';

class Timeline extends Component {

  constructor(props) {
    super(props);

    this.state = {
      itemEventHistory: [],
      itemOwnerHistory: [],
      actors: [],
      varieties: [],
      locations: [],
      councils: [],
      batchName:"",
      tasting:"",
      notFound:null
    };
  }

  componentDidMount = () => {
    this.getInfo(this.props.itemId);
  }

  getInfo = async (itemId) => {
    let data = null;
    if (this.props.mockupData) {
      data = this.props.mockupData;
    } else {
      data = this.props.chainData;
    }
    try {
      const itemEventHistory = await data.getItemEventHistory(itemId);
      const itemOwnerHistory = await data.getItemOwnerHistory(itemId);
      const councils = await data.getItemCouncil(itemId);
      const varieties = await data.getItemVarieties(itemId);
      const locations = await data.getItemLocations(itemId);
      const batchName= await data.getBatchName(itemId);
      const tasting= await data.getTasting(itemId);
      let actors = [];
      for (let i = 0; i < itemOwnerHistory.length; i++) {
        let event = itemOwnerHistory[i];
        const actor = await data.getActorInfo(event.account);
        actors.push(actor);
      }
      this.setState({
        itemEventHistory: itemEventHistory,
        itemOwnerHistory: itemOwnerHistory,
        actors: JSON.parse(JSON.stringify(actors)),
        varieties: varieties,
        locations: locations,
        councils: councils,
        batchName:batchName,
        tasting:tasting,
        notFound:false
      });
    } catch (e) {
      console.log(e);
      this.setState({notFound:true})
    }
  }

  render() {
    // let itemHistory = this.state.itemEventHistory.concat(this.state.itemOwnerHistory);
    let itemHistory = this.state.itemEventHistory

    itemHistory = itemHistory.filter(element => element.timestamp !== 0 || element.type === 'events.typeEnum.newOwner');
    itemHistory = itemHistory.sort((a, b) => {
      const t1 = parseInt(a.timestamp);
      const t2 = parseInt(b.timestamp);
      return (t1 <= t2) ? -1 : 1;
    });
    return (
      <div className="timeline-page">
        <div className="main-content">
          <div className="logo-dop">
            <Link to="/">
              <img src="/images/logo-denominacion-origen-protegida@2x.png" alt="Sidra de Asturias" style={{ "width": "400px", "maxWidth": "90%" }} />
            </Link>
          </div>
          <React.Fragment>
            <CssBaseline />
            {/*
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              align="center"
              noWrap
            >
              Timeline Nº {this.props.itemId}
            </Typography>
            */}
            <TimelineCanvas lang={this.props.lang} showLabel={this.props.showLabel} itemId={this.props.itemId} councils={this.state.councils} varieties={this.state.varieties} locations={this.state.locations} actors={this.state.actors} itemHistory={itemHistory} batchName={this.state.batchName} tasting={this.state.tasting} notFound={this.state.notFound} />
          </React.Fragment>
          <div className="logo-ctic">
            <a href="https://www.fundacionctic.org/es/home" target="_blank" rel="noopener noreferrer"><img src="/images/logo_ctic_3.png" alt="CTIC Centro Tecnológico" style={{ "width": "120px" }} /></a>
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;


