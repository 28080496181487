import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Text from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Ballot from "@material-ui/icons/Ballot";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import HelpIcon from "@mui/icons-material/Help";
import InputAdornment from "@mui/material/InputAdornment";
import { i18n } from "../i18n";
import ImageReader from "./CameraComponent";
import Tour from "reactour";
import { padding } from "@mui/system";

//const TimelineLink = props => <Link to="/timeline" {...props} />
const MockupTimelineLink = (props) => <Link to="/mockuptimeline" {...props} />;
// const imageReader = ImageReader;

export default class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // tagField: '',
      itemId: "",
      isTourOpen: false,
      isShowingMore: false,
    };
  }

  blockScroll = () => {
    document.body.style.overflow = "hidden";
  };

  unblockScroll = () => {
    document.body.style.overflow = "auto";
  };

  handleFocus = (event) => {
    event.target.style.borderColor = "red";
  };

  handleBlur = (event) => {
    event.target.style.borderColor = "";
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
    this.unblockScroll();
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };

  /* A function that is called when the OCR is done. */
  onDoingOCR = (text) => {

    let res = text
    
    if(res === "No encontrado"){
      const lang = this.props.lang;
      res = i18n(lang, "literals.doesntFound")
    }
    const niapa = { target: { value: res } }; // Ñapa as a service ÑaaS
    this.props.handleChange("itemId")(niapa);

    if (text !== "No encontrado" && text !== "") {
      // Call button click of the button with ID seeTrace
      document.getElementById("seeTrace").click();
    }
  };

  render() {
    const { isTourOpen, isShowingMore } = this.state;

    const lang = this.props.lang;

    // Steps for the tour
    const steps = [
      {
        selector: ".item-id",
        content: i18n(lang, "content.step1Tour"), //{i18n(lang, "content.example2")}
      },
      {
        selector: ".CameraButton",
        content: i18n(lang, "content.step5Tour"),
        position: "left",
      },
      {
        selector: ".num-label",
        content: i18n(lang, "content.step2Tour"),
        position: "right",
      },
      {
        selector: ".textField-tag",
        content: i18n(lang, "content.step3Tour"),
      },
      {
        selector: ".seeTrace-button",
        content: i18n(lang, "content.step4Tour"),
      },

      // Here you can add more steps. In selector i use classname of the element, after a dot (.)
      // ...
    ];

    var url = "/timeline/0";
    if (this.props.label) {
      url = "/timeline/" + this.props.label;
    }
    const TimelineLink = (props) => <Link to={url} {...props} />;
    //declare a var to hold the imagereader
    const imageReader = ImageReader;

    const mockUrl =
      "https://sidradop.ctic.es/mockuptimeline/" + this.props.itemId;
    return (
      <div className="main">
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
          //Make the badge green
          accentColor="#742e5c"
        />
        <CssBaseline />
        {/*
         <Typography
          component="h1"
          variant="h4"
          color="inherit"
          align="center"
          noWrap
        >
          Sidra de Asturias
        </Typography> 
          */}
        <div className="main-content">
          <img
            className="main-logo"
            src="/images/logo-denominacion-origen-protegida@2x.png"
            alt="Sidra de Asturias"
            style={{ width: "400px", maxWidth: "90%" }}
          />
          <div className="main-form-wrapper">
            <span className="item-id">
              {/*<span className="cosecha">Cosecha</span>
              <span className="year">2021</span>*/}
              <span className="num-label">
                {" "}
                <span className="num">
                  <strong>P2</strong> Nº <strong>001234</strong>
                </span>
              </span>
            </span>
            <div className="main-form">
              <TextField
                id="outlined-basic"
                className="textField-tag"
                variant="outlined"
                label={i18n(lang, "literals.numLabel")}
                value={this.props.itemId}
                onChange={this.props.handleChange("itemId")}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                type="text"
                sx={{ input: { textAlign: "center" } }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: { fontSize: 23 },
                  startAdornment: (
                    /*This is the icon at the start of the text field*/
                    <InputAdornment position="start">
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          // Call button click of the button with ID CameraIcon. Button is hidden (display: none), and its code is in ImageReader.js
                          document.getElementById("CameraIcon").click()
                        }
                      >
                        <CameraAltIcon
                          className="CameraButton"
                          fontSize="small"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                placeholder="P2001234"
              />
              <br />
              <br />
              <ImageReader onDoingOCR={this.onDoingOCR} />
              &nbsp;&nbsp;&nbsp;
              <Button
                className="seeTrace-button"
                id="seeTrace"
                component={TimelineLink}
                variant="contained"
                color="primary"
              >
                {i18n(lang, "literals.seeTrace")}
              </Button>
              {/* &nbsp;&nbsp;&nbsp;
              <Button
                component={MockupTimelineLink}
                variant="contained"
                color="default"
                style={{ opacity: "0", position: "absolute", top: "0" }}
              >
                <Ballot />
              </Button> */}
            </div>
            <div className="help-section">
              <Button
                color="secondary"
                //Style, button with no border, but underline
                style={{ textDecoration: "underline" }}
                variant="text"
                onClick={() => {
                  this.openTour();
                  this.blockScroll();
                }}
              >
                {i18n(lang, "literals.helpButton")}
              </Button>
            </div>
          </div>
          <div>
            <div
              style={{
                color: "#ddd",
                textAlign: "left",
                padding: "0",
                fontSize: "0.9rem",
              }}
            >
              <h2 style={{ textAlign: "center" }}>
                {i18n(lang, "literals.about")}
              </h2>
              <p>{i18n(lang, "content.example")}</p>
              <p
                style={{
                  margin: "auto",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <Link
                  to="/timeline/195455353"
                  style={{
                    display: "inlineBlock",
                    padding: "5px 6px 3px",
                    borderRadius: "4px",
                    textDecoration: "none",
                    color: "#633006",
                    background: "#fff0e3",
                    margin: "6px 10px",
                  }}
                >
                  <strong>{i18n(lang, "content.example1")}</strong>
                </Link>{" "}
                <Link
                  to="/timeline/182084001"
                  style={{
                    display: "inlineBlock",
                    padding: "5px 6px 3px",
                    borderRadius: "4px",
                    textDecoration: "none",
                    color: "#633006",
                    background: "#fff0e3",
                    margin: "6px 10px",
                  }}
                >
                  <strong>{i18n(lang, "content.example2")}</strong>{" "}
                </Link>
              </p>
              <p>{i18n(lang, "content.aldeacero")}</p>
              <p>
                <strong>{i18n(lang, "content.telosInfo1")}</strong>{" "}
                {i18n(lang, "content.telosInfo")}
              </p>
              <p>
                <strong>{i18n(lang, "content.consejoInfo1")}</strong>{" "}
                {i18n(lang, "content.consejoInfo")}
              </p>

              <p style={{ textAlign: "right" }}>
                <Link to="/about" style={{ color: "#ddd" }}>
                  {i18n(lang, "literals.about")}
                </Link>{" "}
                |{" "}
                <a
                  href="http://telosfoundation.io"
                  style={{ color: "#ddd" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Powered by Telos
                </a>
              </p>
              <br></br>
              <hr></hr>
            </div>
            <div style={{ margin: "30px 0 0" }}>
              <a
                href="https://www.fundacionctic.org/es/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/logo_ctic_3.png"
                  alt="CTIC Centro Tecnológico"
                  style={{ width: "120px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
