export const AppleVarieties = Object.freeze({
    AMARIEGA: 'apples.varieties.amariega',
    ANTONONA: 'apples.varieties.antonona',
    ARBEYA: 'apples.varieties.arbeya',
    BELDREDO: 'apples.varieties.beldredo',
    BLANQUINA: 'apples.varieties.blanquina',
    CARRANDONA: 'apples.varieties.carrandona',
    CARRIO: 'apples.varieties.carrio',
    CELSO: 'apples.varieties.celso',
    CHATABLANCA: 'apples.varieties.chatablanca',
    CHATAENCARNADA: 'apples.varieties.chataencarnada',
    CLADURINA: 'apples.varieties.cladurina',
    CLADURINAAMARGOACIDA: 'apples.varieties.cladurinaamargoacida',
    CLARA: 'apples.varieties.clara',
    COLLAINA: 'apples.varieties.collaina',
    COLLAOS: 'apples.varieties.collaos',
    COLORADONA: 'apples.varieties.coloradona',
    COLORAAMARGA: 'apples.varieties.coloraamarga',
    CORCHU: 'apples.varieties.corchu',
    CRISTALINA: 'apples.varieties.cristalina',
    DELARIEGA: 'apples.varieties.delariega',
    DURA: 'apples.varieties.dura',
    DURONDARROES: 'apples.varieties.durondarroes',
    DURONENCARNADO: 'apples.varieties.duronencarnado',
    DURCOLORA: 'apples.varieties.durcolora',
    DURONADETRESALI: 'apples.varieties.duronadetresali',
    ERNESTINA: 'apples.varieties.ernestina',
    FRESNOSA: 'apples.varieties.fresnosa',
    FUENTES: 'apples.varieties.fuentes',
    JOSEFA: 'apples.varieties.josefa',
    LIMONMONTES: 'apples.varieties.limonmontes',
    LIN: 'apples.varieties.lin',
    MADIEDO: 'apples.varieties.madiedo',
    MARIAELENA: 'apples.varieties.mariaelena',
    MARINANA: 'apples.varieties.marinana',
    MARTINA: 'apples.varieties.martina',
    MEANA: 'apples.varieties.meana',
    MIYERES: 'apples.varieties.miyeres',
    MONTESDEFLOR: 'apples.varieties.montesdeflor',
    MONTESDELALLAMERA: 'apples.varieties.montesdelallamera',
    MONTOTO: 'apples.varieties.montoto',
    PANQUERINA: 'apples.varieties.panquerina',
    PARAGUAS: 'apples.varieties.paraguas',
    PENARUDES: 'apples.varieties.penarudes',
    PEREZOSA: 'apples.varieties.perezosa',
    PERICO: 'apples.varieties.perico',
    PERRACABIELLA: 'apples.varieties.perracabiella',
    PERURICO: 'apples.varieties.perurico',
    PERURICOPRECOZ: 'apples.varieties.peruricoprecoz',
    PICON: 'apples.varieties.picon',
    PRIETA: 'apples.varieties.prieta',
    RAXAO: 'apples.varieties.raxao',
    RAXAREGA: 'apples.varieties.raxarega',
    RAXILAACIDA: 'apples.varieties.raxilaacida',
    RAXILADULCE: 'apples.varieties.raxiladulce',
    RAXILARAYADA: 'apples.varieties.raxilarayada',
    RAXINAAMARGA: 'apples.varieties.raxinaamarga',
    RAXINAACIDA: 'apples.varieties.raxinaacida',
    RAXINADULCE: 'apples.varieties.raxinadulce',
    RAXINAMARELO: 'apples.varieties.raxinamarelo',
    RAXONAACIDA: 'apples.varieties.raxonaacida',
    RAXONADULCE: 'apples.varieties.raxonadulce',
    REGONA: 'apples.varieties.regona',
    REINETACARAVIA: 'apples.varieties.reinetacaravia',
    REINETAENCARNADA: 'apples.varieties.reinetaencaranda',
    REINETAPINTA: 'apples.varieties.reinetapinta',
    REPINALDOCARAVIA: 'apples.varieties.repinaldocaravia',
    REPINALDODEHUESO: 'apples.varieties.repinaldodehueso',
    ROSADONA: 'apples.varieties.rosadona',
    SANJUSTO: 'apples.varieties.sanjusto',
    SANROQUENA: 'apples.varieties.sanroquena',
    SOLARINA: 'apples.varieties.solarina',
    SUCU: 'apples.varieties.sucu',
    TEORICA: 'apples.varieties.teorica',
    VERDIALONA: 'apples.varieties.verdialona',
    VERDOSA: 'apples.varieties.verdosa',
    XUANINA: 'apples.varieties.xuanina',
    MEZCLA: 'apples.varieties.mezcla'
    
});

export const AppleVarietiesImages = Object.freeze({
    [AppleVarieties.AMARIEGA]: "amariega.jpg",
    [AppleVarieties.ANTONONA]: "antonona.jpg",
    [AppleVarieties.ARBEYA]: "arbeya.jpg",
    [AppleVarieties.BELDREDO]: "beldredo.jpg",
    [AppleVarieties.BLANQUINA]: "blanquina.jpg",
    [AppleVarieties.CARRANDONA]: "carrandona.jpg",
    [AppleVarieties.CARRIO]: "carrio.jpg",
    [AppleVarieties.CELSO]: "celso.jpg",
    [AppleVarieties.CHATABLANCA]: "chatablanca.jpg",
    [AppleVarieties.CHATAENCARNADA]: "chataencarnada.jpg",
    [AppleVarieties.CLADURINA]: "cladurina.jpg",
    [AppleVarieties.CLADURINAAMARGOACIDA]: "cladurinaamargoacida.jpg",
    [AppleVarieties.CLARA]: "clara.jpg",
    [AppleVarieties.COLLAINA]: "collaina.jpg",
    [AppleVarieties.COLLAOS]: "collaos.jpg",
    [AppleVarieties.COLORADONA]: "coloradona.jpg",
    [AppleVarieties.COLORAAMARGA]: "coloraamarga.jpg",
    [AppleVarieties.CORCHU]: "corchu.jpg",
    [AppleVarieties.CRISTALINA]: "cristalina.jpg",
    [AppleVarieties.DELARIEGA]: "delariega.jpg",
    [AppleVarieties.DURA]: "dura.jpg",
    [AppleVarieties.DURONDARROES]: "durondarroes.jpg",
    [AppleVarieties.DURONENCARNADO]: "duronencarnado.jpg",
    [AppleVarieties.DURCOLORA]: "durcolora.jpg",
    [AppleVarieties.DURONADETRESALI]: "duronadetresali.jpg",
    [AppleVarieties.ERNESTINA]: "ernestina.jpg",
    [AppleVarieties.FRESNOSA]: "fresnosa.jpg",
    [AppleVarieties.FUENTES]: "fuentes.jpg",
    [AppleVarieties.JOSEFA]: "josefa.jpg",
    [AppleVarieties.LIMONMONTES]: "limonmontes.jpg",
    [AppleVarieties.LIN]: "lin.jpg",
    [AppleVarieties.MADIEDO]: "madiedo.jpg",
    [AppleVarieties.MARIAELENA]: "mariaelena.jpg",
    [AppleVarieties.MARINANA]: "marinana.jpg",
    [AppleVarieties.MARTINA]: "martina.jpg",
    [AppleVarieties.MEANA]: "meana.jpg",
    [AppleVarieties.MIYERES]: "miyeres.jpg",
    [AppleVarieties.MONTESDEFLOR]: "montesdeflor.jpg",
    [AppleVarieties.MONTESDELALLAMERA]: "montesdelallamera.jpg",
    [AppleVarieties.MONTOTO]: "montoto.jpg",
    [AppleVarieties.PANQUERINA]: "panquerina.jpg",
    [AppleVarieties.PARAGUAS]: "paraguas.jpg",
    [AppleVarieties.PENARUDES]: "penarudes.jpg",
    [AppleVarieties.PEREZOSA]: "perezosa.jpg",
    [AppleVarieties.PERICO]: "perico.jpg",
    [AppleVarieties.PERRACABIELLA]: "perracabiella.jpg",
    [AppleVarieties.PERURICO]: "perurico.jpg",
    [AppleVarieties.PERURICOPRECOZ]: "peruricoprecoz.jpg",
    [AppleVarieties.PICON]: "picon.jpg",
    [AppleVarieties.PRIETA]: "prieta.jpg",
    [AppleVarieties.RAXAO]: "raxao.jpg",
    [AppleVarieties.RAXAREGA]: "raxarega.jpg",
    [AppleVarieties.RAXILAACIDA]: "raxilaacida.jpg",
    [AppleVarieties.RAXILADULCE]: "raxiladulce.jpg",
    [AppleVarieties.RAXILARAYADA]: "raxilarayada.jpg",
    [AppleVarieties.RAXINAAMARGA]: "raxinaamarga.jpg",
    [AppleVarieties.RAXINAACIDA]: "raxinaacida.jpg",
    [AppleVarieties.RAXINADULCE]: "raxinadulce.jpg",
    [AppleVarieties.RAXINAMARELO]: "raxinamarelo.jpg",
    [AppleVarieties.RAXONAACIDA]: "raxonaacida.jpg",
    [AppleVarieties.RAXONADULCE]: "raxonadulce.jpg",
    [AppleVarieties.REGONA]: "regona.jpg",
    [AppleVarieties.REINETACARAVIA]: "reinetacaravia.jpg",
    [AppleVarieties.REINETAENCARNADA]: "reinetaencarnada.jpg",
    [AppleVarieties.REINETAPINTA]: "reinetapinta.jpg",
    [AppleVarieties.REPINALDOCARAVIA]: "repinaldocaravia.jpg",
    [AppleVarieties.REPINALDODEHUESO]: "repinaldodehueso.jpg",
    [AppleVarieties.ROSADONA]: "rosadona.jpg",
    [AppleVarieties.SANJUSTO]: "sanjusto.jpg",
    [AppleVarieties.SANROQUENA]: "sanroquena.jpg",
    [AppleVarieties.SOLARINA]: "solarina.jpg",
    [AppleVarieties.SUCU]: "sucu.jpg",
    [AppleVarieties.TEORICA]: "teorica.jpg",
    [AppleVarieties.VERDIALONA]: "verdialona.jpg",
    [AppleVarieties.VERDOSA]: "verdosa.jpg",
    [AppleVarieties.XUANINA]: "xuanina.jpg",
    [AppleVarieties.MEZCLA]: "mezcla.jpg",
})
