export const ActorsTypes = Object.freeze({
  ISSUER: 'actors.typeEnum.issuer',
  WINERY: 'actors.typeEnum.winery',
  DISTRIBUTOR: 'actors.typeEnum.distributor',
  VENDOR: 'actors.typeEnum.vendor'
});

export const EventsTypes = Object.freeze({
  NEWOWNER: 'events.typeEnum.newOwner',
  COLLECTED: 'events.typeEnum.collected',
  PRESSING: 'events.typeEnum.pressing',
  QUALIFIED: 'events.typeEnum.qualified',
  CORKED: 'events.typeEnum.corked',
  DISTRIBUTION: 'events.typeEnum.distribution',
  BOTTLEFERMENTATION: 'events.typeEnum.bottleFermentation',
  DEPOSITFERMENTATION: 'events.typeEnum.depositFermentation',
  FILTERED: 'events.typeEnum.filtered',
  DEGUELLE: 'events.typeEnum.deguelle',
  BOTTLED: 'events.typeEnum.bottled',
});

export const ActorsTypesIcons = Object.freeze({
  [ActorsTypes.ISSUER]: '',
  [ActorsTypes.WINERY]: 'fa-warehouse',
  [ActorsTypes.DISTRIBUTOR]:'fa-truck',
  [ActorsTypes.VENDOR]: 'fa-store'
})

export const EventsTypesIcons = Object.freeze({
  [EventsTypes.NEWOWNER]: null,
  [EventsTypes.COLLECTED]: null,
  [EventsTypes.PRESSING]: 'fa-compress-arrows-alt',
  [EventsTypes.QUALIFIED]: 'fa-tags',
  [EventsTypes.CORKED]: 'fa-wine-bottle',
  [EventsTypes.DISTRIBUTION]: 'fa-box',
  // todo: replace placeholder icons
  [EventsTypes.BOTTLEFERMENTATION]: 'fa-wine-bottle',
  [EventsTypes.DEPOSITFERMENTATION]: 'fa-water',
  [EventsTypes.FILTERED]: 'fa-filter',
  [EventsTypes.DEGUELLE]: 'fa-wind',
  [EventsTypes.BOTTLED]: 'fa-wine-bottle',
})

export const EventsTypesImages = Object.freeze({
  [EventsTypes.NEWOWNER]: null,
  [EventsTypes.COLLECTED]: null,
  [EventsTypes.PRESSING]: '/images/events/prensa-manzana.png',
  [EventsTypes.QUALIFIED]: '/images/events/qualified.jpg',
  [EventsTypes.CORKED]: '/images/events/corchar.png',
  [EventsTypes.DISTRIBUTION]: '/images/events/furgo-sidra.png',
  // todo: replace placeholder images
  [EventsTypes.BOTTLEFERMENTATION]: '/images/events/bottleFermentationPlaceholder.png',
  [EventsTypes.DEPOSITFERMENTATION]: '/images/events/tankPlaceholder.png',
  [EventsTypes.FILTERED]: '/images/events/filteringFix.png',
  [EventsTypes.DEGUELLE]: '/images/events/deguellePlaceholder.png',
  [EventsTypes.BOTTLED]: '/images/events/corchar.png',
})
