import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RCamera } from 'react-camera-media-stream';
import 'react-camera-media-stream/dist/index.css';
import IconButton from "@material-ui/core/IconButton";
import axios from 'axios';
import { i18n } from "../i18n";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

export default function ImageReader(props) {

    //State of ImageReader component (camera component). It is used to show or hide the camera component
    const [state, setState] = useState();

    /**
     * It takes an image (parameter ), sends it to the OCR service, and then calls the onDoingOCR function with the
     * response
     * @param img - The image to be processed.
     */
    async function doOCR(img) {

        setState(null);
        const formData = new FormData();
        formData.append('file', img);

        // const urlOcr = 'http://localhost:65002/ocr'; //Only for local testing
        // const urlOcr = 'https://sidracam.test.ctic.es/ocr'; //Only for testing devit
        //processImage
        const urlOcr = 'https://sidradop.ctic.es/ocr/processImage'; //Production url

        axios.post(urlOcr, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(
            // Call onDoingOCR function with the response. "onDoingOCR" is a function of the parent component
            response => props.onDoingOCR(response.data)
        ).catch(error => {
            console.log(error);
        });
    };


    return (
        <>
        {/*The button of the component doesnt display but still exist. This is because i call it from the button with the camera icon*/}
            <IconButton id="CameraIcon" style={{ display: "none" }} color="primary" 
            onClick={() => setState({ state: true })}
            >
                <CameraAltIcon fontSize="large"/>
            </IconButton>
            {/* Camera component. At start is hiddenm and when user clicks the button, it appears full scrceen. When close, it hides. */}
            {state ?
                <RCamera
                    isConfirm={false}
                    onTakePicture={(data) => {
                        
                        // When take picture, state false and call doOCR
                        setState(false)
                        doOCR(data.split(",")[1]);

                    }}
                    onClose={() => {
                        // When close, state false
                        setState(false)
                    }}
                    isFullScreen={true}
                    max={1920}
                    isTorch={false}
                /> : ''
            }
        </>
    );

}
