export const AsturianCouncils = Object.freeze({
    ALLANDE: 'Allande',
    ALLER: 'Aller',
    AMIEVA: 'Amieva',
    AVILES: 'Avilés',
    BELMONTEDEMIRANDA: 'Belmonte de Miranda',
    BIMENES: 'Bimenes',
    BOAL: 'Boal',
    CABRALES: 'Cabrales',
    CABRANES: 'Cabranes',
    CANDAMO: 'Candamo',
    CANGASDEONIS: 'Cangas de Onís',
    CANGASDELNARCEA: 'Cangas del Narcea',
    CARAVIA: 'Caravia',
    CARRENO: 'Carreño',
    CASO: 'Caso',
    CASTRILLON: 'Castrillón',
    CASTROPOL: 'Castropol',
    COANA: 'Coaña',
    COLUNGA: 'Colunga',
    CORVERADEASTURIAS: 'Corvera de Asturias',
    CUDILLERO: 'Cudillero',
    DEGANA: 'Degaña',
    ELFRANCO: 'El Franco',
    GIJON: 'Gijón',
    GOZON: 'Gozón',
    GRADO: 'Grado',
    GRANDASDESALIME: 'Grandas de Salime',
    IBIAS: 'Ibias',
    ILLANO: 'Illano',
    ILLAS: 'Illas',
    LANGREO: 'Langreo',
    LAVIANA: 'Laviana',
    LENA: 'Lena',
    LLANERA: 'Llanera',
    LLANES: 'Llanes',
    MIERES: 'Mieres',
    MORCIN: 'Morcín',
    MUROSDENALON: 'Muros de Nalón',
    NAVA: 'Nava',
    NAVIA: 'Navia',
    NORENA: 'Noreña',
    ONIS: 'Onís',
    OVIEDO: 'Oviedo',
    PARRES: 'Parres',
    PENAMELLERAALTA: 'Peñamellera Alta',
    PENAMELLERABAJA: 'Peñamellera Baja',
    PESOZ: 'Pesoz',
    PILONA: 'Piloña',
    PONGA: 'Ponga',
    PRAVIA: 'Pravia',
    PROAZA: 'Proaza',
    QUIROS: 'Quirós',
    LASREGUERAS: 'Las Regueras',
    RIBADEDEVA: 'Ribadedeva',
    RIBADESELLA: 'Ribadesella',
    RIBERADEARRIBA: 'Ribera de Arriba',
    RIOSA: 'Riosa',
    SALAS: 'Salas',
    SANMARTINDEOSCOS: 'San Martín de Oscos',
    SANMARTINDELREYAURELIO: 'San Martín del Rey Aurelio',
    SANTIRSODEABRES: 'San Tirso de Abres',
    SANTAEULALIADEOSCOS: 'Santa Eulalia de Oscos',
    SANTOADRIANO: 'Santo Adriano',
    SARIEGO: 'Sariego',
    SIERO: 'Siero',
    SOBRESCOBIO: 'Sobrescobio',
    SOMIEDO: 'Somiedo',
    SOTODELBARCO: 'Soto del Barco',
    TAPIADECASARIEGO: 'Tapia de Casariego',
    TARAMUNDI: 'Taramundi',
    TEVERGA: 'Teverga',
    TINEO: 'Tineo',
    VALDES: 'Valdés',
    VEGADEO: 'Vegadeo',
    VILLANUEVADEOSCOS: 'Villanueva de Oscos',
    VILLAVICIOSA: 'Villaviciosa',
    VILLAYON: 'Villayón',
    YERNESYTAMEZA: 'Yernes y Tameza'
  });

  export const AsturianCouncilsMapIndex = Object.freeze({
    [AsturianCouncils.ALLANDE]: 1,
    [AsturianCouncils.ALLER]: 2,
    [AsturianCouncils.AMIEVA]: 3,
    [AsturianCouncils.AVILES]: 4,
    [AsturianCouncils.BELMONTEDEMIRANDA]: 5,
    [AsturianCouncils.BIMENES]: 6,
    [AsturianCouncils.BOAL]: 7,
    [AsturianCouncils.CABRALES]: 8,
    [AsturianCouncils.CABRANES]: 9,
    [AsturianCouncils.CANDAMO]: 10,
    [AsturianCouncils.CANGASDEONIS]: 11,
    [AsturianCouncils.CANGASDELNARCEA]: 12,
    [AsturianCouncils.CARAVIA]: 13,
    [AsturianCouncils.CARRENO]: 14,
    [AsturianCouncils.CASO]: 15,
    [AsturianCouncils.CASTRILLON]: 16,
    [AsturianCouncils.CASTROPOL]: 17,
    [AsturianCouncils.COANA]: 18,
    [AsturianCouncils.COLUNGA]: 19,
    [AsturianCouncils.CORVERADEASTURIAS]: 20,
    [AsturianCouncils.CUDILLERO]: 21,
    [AsturianCouncils.DEGANA]: 22,
    [AsturianCouncils.ELFRANCO]: 23,
    [AsturianCouncils.GIJON]: 24,
    [AsturianCouncils.GOZON]: 25,
    [AsturianCouncils.GRADO]: 26,
    [AsturianCouncils.GRANDASDESALIME]: 27,
    [AsturianCouncils.IBIAS]: 28,
    [AsturianCouncils.ILLANO]: 29,
    [AsturianCouncils.ILLAS]: 30,
    [AsturianCouncils.LANGREO]: 31,
    [AsturianCouncils.LAVIANA]: 32,
    [AsturianCouncils.LENA]: 33,
    [AsturianCouncils.LLANERA]: 34,
    [AsturianCouncils.LLANES]: 35,
    [AsturianCouncils.MIERES]: 36,
    [AsturianCouncils.MORCIN]: 37,
    [AsturianCouncils.MUROSDENALON]: 38,
    [AsturianCouncils.NAVA]: 39,
    [AsturianCouncils.NAVIA]: 40,
    [AsturianCouncils.NORENA]: 41,
    [AsturianCouncils.ONIS]: 42,
    [AsturianCouncils.OVIEDO]: 43,
    [AsturianCouncils.PARRES]: 44,
    [AsturianCouncils.PENAMELLERAALTA]: 45,
    [AsturianCouncils.PENAMELLERABAJA]: 46,
    [AsturianCouncils.PESOZ]: 47,
    [AsturianCouncils.PILONA]: 48,
    [AsturianCouncils.PONGA]: 49,
    [AsturianCouncils.PRAVIA]: 50,
    [AsturianCouncils.PROAZA]: 51,
    [AsturianCouncils.QUIROS]: 52,
    [AsturianCouncils.LASREGUERAS]: 53,
    [AsturianCouncils.RIBADEDEVA]: 54,
    [AsturianCouncils.RIBADESELLA]: 55,
    [AsturianCouncils.RIBERADEARRIBA]: 56,
    [AsturianCouncils.RIOSA]: 57,
    [AsturianCouncils.SALAS]: 58,
    [AsturianCouncils.SANMARTINDEOSCOS]: 59,
    [AsturianCouncils.SANMARTINDELREYAURELIO]: 60,
    [AsturianCouncils.SANTIRSODEABRES]: 61,
    [AsturianCouncils.SANTAEULALIADEOSCOS]: 62,
    [AsturianCouncils.SANTOADRIANO]: 63,
    [AsturianCouncils.SARIEGO]: 64,
    [AsturianCouncils.SIERO]: 65,
    [AsturianCouncils.SOBRESCOBIO]: 66,
    [AsturianCouncils.SOMIEDO]: 67,
    [AsturianCouncils.SOTODELBARCO]: 68,
    [AsturianCouncils.TAPIADECASARIEGO]: 69,
    [AsturianCouncils.TARAMUNDI]: 70,
    [AsturianCouncils.TEVERGA]: 71,
    [AsturianCouncils.TINEO]: 72,
    [AsturianCouncils.VALDES]: 73,
    [AsturianCouncils.VEGADEO]: 74,
    [AsturianCouncils.VILLANUEVADEOSCOS]: 75,
    [AsturianCouncils.VILLAVICIOSA]: 76,
    [AsturianCouncils.VILLAYON]: 77,
    [AsturianCouncils.YERNESYTAMEZA]: 78
  });