import React from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom'
import Main from './components/Main'
import Timeline from './components/Timeline'
import ScrollToTop from './components/ScrollTop'
import About from './components/About'

export default props => (
  <HashRouter>
    <ScrollToTop>
      <Switch>
        <Route exact path='/' render={() =>
          <Main {...props} />}
        />
        {/* <Route exact path='/timeline' render={() =>
          <Timeline lang={props.lang} itemId={props.itemId} chainData={props.chainData} />}
        /> */}
        <Route exact path='/mockuptimeline' render={() =>
          <Timeline lang={props.lang} itemId={props.itemId} mockupData={props.mockupData} />}
        />
        <Route exact path='/timeline/:itemId' render={(matchProps) =>
          <Timeline showLabel={props.itemId} itemId={matchProps.match.params.itemId} lang={props.lang} chainData={props.chainData}/>}
        />
        <Route exact path='/about' render={() =>
          <About {...props} />}
        />
      </Switch>
    </ScrollToTop>
  </HashRouter>
)