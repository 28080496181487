import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';
import Routes from './routes'
import { EosDataConnector } from './blockchain/eosDataConnector';
import { MockupDataConnector } from './blockchain/mockupDataConnector';
import { blue, indigo, amazon } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#a19b95' // blue[900]
    },
    primary: {
      main: '#2e7446'// indigo[700]
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});


let deferredPrompt;
// window.addEventListener('beforeinstallprompt', (e) => {
//   // Prevent Chrome 67 and earlier from automatically showing the prompt
//   e.preventDefault();
//   // Stash the event so it can be triggered later.
//   deferredPrompt = e;
//   //alert("Install");
// });


export default class App extends Component {

  constructor(props) {
    super(props);

    // get the data connector url from the environment TELOS_API_URL
    // if not defined, use the default value
    let dataConnectorUrl =  "https://api.theteloscope.io";//"http://172.16.102.98:58888"
    
    const chainData = new EosDataConnector(dataConnectorUrl);
    const mockupData = new MockupDataConnector();

    this.state = {
      itemId: '',
      chainData: chainData,
      mockupData: mockupData,
      lang: 'es'
    };
  }

  handleChange = name => event => {
    // console.log("Called handleChange: " + name + " " + event.target.value);
    this.setState({ [name]: event.target.value });
  };

  handleClick = event => {
    this.setState({ lang: event.target.getAttribute("data-lang") });
    //alert(event.target.getAttribute("data-lang"));
  };
  convertLabel(label){
    var parts= label.toLowerCase().match(/([A-Za-z]+)([0-9]+)/);
    if(parts) {
      var letter = (parts[1].charCodeAt(0) - 97 + 1).toString()
      var number = (parts[2]).toString()
      return(letter.concat(number))
    }
  }
  render() {
    var label = this.convertLabel(this.state.itemId);
    return (
      <div className="app">
        <div className="languages">
          <a href="https://www.fundacionctic.org/es/home" id="logo-header" target="_blank" rel="noopener noreferrer"><img src="/images/logo_ctic_3.png" alt="CTIC Centro Tecnológico" /></a>
          <span role="link" onClick={this.handleClick} data-lang="es">ES</span>&nbsp;-&nbsp;
          <span role="link" onClick={this.handleClick} data-lang="en">EN</span>&nbsp;-&nbsp;
          <span role="link" onClick={this.handleClick} data-lang="ast">AST</span>
        </div>
        <MuiThemeProvider theme={theme}>
          <Routes lang={this.state.lang} label={label} itemId={this.state.itemId} chainData={this.state.chainData} mockupData={this.state.mockupData} handleChange={this.handleChange} />
        </MuiThemeProvider>
      </div>
    );
  }
}
