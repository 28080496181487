import React, {Component} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom';
import './Timeline.css';
import { i18n } from "../i18n";

class About extends Component{
    
    render(){
        var lang=this.props.lang;
        return(<div className="timeline-page">
        <div className="main-content">
          <div className="logo-dop">
            <Link to="/">
              <img src="/images/logo-denominacion-origen-protegida@2x.png" alt="Sidra de Asturias" style={{ "width": "400px", "maxWidth": "90%" }} />
            </Link>
          </div>
          <React.Fragment>
            <CssBaseline />
            {/*
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              align="center"
              noWrap
            >
              Timeline Nº {this.props.itemId}
            </Typography>
            */}
            <div style={{ "color": "#ddd", "textAlign": "left", "padding": "0", "fontSize": "0.9rem" }}>
              <p style={{fontWeight:"bold"}}>{i18n(lang, "content.aboutTitle")}</p>
              <p>{i18n(lang, "content.about1")}</p>
              <p>{i18n(lang, "content.about2")}</p>
              <p>{i18n(lang, "content.about3")}</p>
            </div>
          </React.Fragment>
          <div className="logo-ctic">
            <a href="https://www.fundacionctic.org/es/home" target="_blank" rel="noopener noreferrer"><img src="/images/logo_ctic_3.png" alt="CTIC Centro Tecnológico" style={{ "width": "120px" }} /></a>
          </div>
        </div>
      </div>)
    }

}

export default About;